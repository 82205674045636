import React, { Component } from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { Facebook } from "./Facebook";
import { Twitter } from "./Twitter";

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

export default class SEO extends Component {
  render() {
    const {
      title,
      desc,
      path = null,
      post,
      banner,
      yoast = [],
      location,
    } = this.props;
    const {
      meta_robots_noindex,
      meta_robots_nofollow,
      twitter_image,
      opengraph_image,
      metaDescription,
    } = yoast;
    return (
      <StaticQuery
        query={graphql`
          query {
            wordpressWpSettings {
              siteUrl
              wordpressUrl
              defaultTitle: title
              defaultBanner: banner
              siteLanguage
              twitter
              shortName
            }
          }
        `}
        render={({
          wordpressWpSettings: {
            defaultTitle,
            siteUrl,
            defaultBanner,
            siteLanguage,
            twitter = null,
            shortName,
          },
        }) => {
          const descriptionToUse = metaDescription ? metaDescription : "";
          const seo = {
            title: title || defaultTitle,
            description: desc || descriptionToUse,
            image: banner || defaultBanner,
            url:
              location && location.pathname && `${siteUrl}${location.pathname}`,
          };

          let schemaOrgJSONLD = [
            {
              "@context": "http://schema.org",
              "@type": "WebSite",
              "@id": siteUrl,
              url: siteUrl,
              name: defaultTitle,
            },
          ];

          if (post) {
            schemaOrgJSONLD = [
              {
                "@context": "http://schema.org",
                "@type": "BlogPosting",
                "@id": seo.url,
                url: seo.url,
                name: title,
                headline: title,
                image: {
                  "@type": "ImageObject",
                  url: `${seo.image}`,
                },
                description: seo.description,
                datePublished: post.data,
                dateModified: post.data,
                author: {
                  "@type": "Person",
                  name: post.author || defaultTitle,
                },
                publisher: {
                  "@type": "Organization",
                  name: post.author || defaultTitle,
                  logo: {
                    "@type": "ImageObject",
                    url: `/logos/logo-1200x630.jpg`,
                  },
                },
                isPartOf: siteUrl,
                mainEntityOfPage: {
                  "@type": "WebSite",
                  "@id": siteUrl,
                },
              },
            ];
          }

          return (
            <>
              <Helmet title={seo.title}>
                <html lang={siteLanguage} />
                <meta name="description" content={seo.description} />
                <meta name="image" content={`${siteUrl}${seo.image}`} />
                <meta name="apple-mobile-web-app-title" content={shortName} />
                <meta name="application-name" content={shortName} />
                {meta_robots_noindex && (
                  <meta
                    name="robots"
                    content={`noindex${
                      meta_robots_nofollow ? ",nofollow" : ",follow"
                    }`}
                  />
                )}
                <script type="application/ld+json">
                  {JSON.stringify(schemaOrgJSONLD)}
                </script>
                <script>{`window.loopa=window.loopa||[];!function(){if("undefined"==typeof window._loopaSmartEvents){window._loopaSmartEvents={};var e="623927f61a74f7d3db0b2b0d",t=function(){var t=document.createElement("script");t.src="//rtb.loopa.net.au/Pub/SmartEvents?pId="+e,t.setAttribute("async","true"),t.type="text/javascript";var a=document.createElement("script");a.src="https://ads-cdn.loopaautomate.com/scripts/static/loopaSmartEvents.min.js",a.setAttribute("async","true"),a.type="text/javascript";var n=document.getElementsByTagName("head")[0];if(n||(n=document.getElementsByTagName("script")[0].parentNode),n.appendChild(t),n.appendChild(a),!document.getElementById("___lpHead")){var d=document.createElement("span");d.id="___lpHead",d.setAttribute("style","display:none;"),n=document.getElementsByTagName("body")[0],n||(n=document.getElementsByTagName("head")[0]),n.appendChild(d)}};"complete"===document.readyState?t():window.addEventListener?window.addEventListener("load",t,!1):window.attachEvent?window.attachEvent("onload",t):t()}}();`}</script>
              </Helmet>
              <Facebook
                desc={seo.description}
                image={
                  opengraph_image && opengraph_image.source_url
                    ? `${siteUrl}${opengraph_image.source_url}`
                    : `${siteUrl}${seo.image}`
                }
                title={seo.title}
                type={post ? "blog" : "article"}
                url={seo.url}
              />
              <Twitter
                title={seo.title}
                image={
                  twitter_image && twitter_image.source_url
                    ? `${siteUrl}${twitter_image.source_url}`
                    : `${siteUrl}${seo.image}`
                }
                desc={seo.description}
                username={twitter}
              />
            </>
          );
        }}
      />
    );
  }
}
